<script>

  import {base} from "$app/paths";
  import {onMount} from "svelte";

  let mountains_source = [
      'mountains_2.png',
    'mountains_3.png',
    'mountains_4.png',
    'mountains_5.png',
    'mountains_6.png',
    //'mountains_7.png',
    'mountains_8.png',
  ]
  function get_random_int(max) {
    return Math.floor(Math.random() * max);
  }
  let source_index = get_random_int(mountains_source.length)
  onMount(()=>{
      console.log("have image", mountains_source[source_index])
  }
  )
</script>
<img class="header-img" src={`${base}/images/${mountains_source[source_index]}`} alt="Mountains with space pornography"/>



<style>

  .header-img{
    object-fit: contain;
    max-width: 100%;
    height: auto;
  }


</style>
